// Home.jsx
import React, { useState, useEffect } from 'react';
import './CSS/Home.css';
import { usePageContent } from '../Context/PageContentContext';
import images from '../constants/images';
import { Link, useNavigate } from 'react-router-dom';
import { navigationItems } from '../constants/navigationItems';

const Home = () => {
  const { setPageContent, setPageVisible, setSelectedNavItem } =
    usePageContent();
  const navigate = useNavigate();

  // State to trigger desktop image change after 11 seconds
  const [isImageChanged, setIsImageChanged] = useState(
    sessionStorage.getItem('isImageChanged') === 'true'
  );

  // State to trigger moving image change after 11 seconds
  const [isMobileImageChanged, setIsMobileImageChanged] = useState(
    sessionStorage.getItem('isMobileImageChanged') === 'true'
  );

  useEffect(() => {
    // Timer for main image (desktop)
    if (!isImageChanged) {
      const timer = setTimeout(() => {
        setIsImageChanged(true);
        sessionStorage.setItem('isImageChanged', 'true'); // Save to sessionStorage
      }, 10000); // 10 seconds

      return () => clearTimeout(timer);
    }
  }, [isImageChanged]);

  useEffect(() => {
    // Timer for moving image
    if (!isMobileImageChanged) {
      const timer = setTimeout(() => {
        setIsMobileImageChanged(true);
        sessionStorage.setItem('isMobileImageChanged', 'true'); // Save to sessionStorage
      }, 10000); // 10 seconds

      return () => clearTimeout(timer);
    }
  }, [isMobileImageChanged]);

  const handleNavigation = (item) => {
    setPageContent(item.content);
    setPageVisible(true);
    setSelectedNavItem(item.label);
    navigate(item.to);
  };

  const filteredItems = navigationItems.filter(
    (item) => item.id === 'project' || item.id === 'contact'
  );

  return (
    <div className="app__bg app__header app__wrapper section__padding">
      <div className="app__wrapper_img">
        <img
          src={images.heroDesktop}
          alt="hero-desktop"
          className={`image ${isImageChanged ? 'fade-out' : 'fade-in'}`}
        />
        <img
          src={images.heroDesktop2}
          alt="hero-desktop2"
          className={`image ${isImageChanged ? 'fade-in' : 'fade-out'}`}
        />
        <img
          src={images.heroMobile}
          alt="hero-mobile"
          className={`img-mobile ${
            isMobileImageChanged ? 'fade-out' : 'fade-in'
          }`}
        />
        <img
          src={images.heroMobile2}
          alt="hero-mobile2"
          className={`img-mobile ${
            isMobileImageChanged ? 'fade-in' : 'fade-out'
          }`}
        />
      </div>
      <div className="app__wrapper_info">
        <h1 className="app__header-h1">
          Bienvenue sur <br />
          <span>Go Dev</span>
        </h1>
        <p className="p__custom-home">
          Développeur web fullstack créatif, je m'appelle Guichard Olivier, j'ai
          pour objectif de satisfaire mes clients en leur offrant des sites web
          performants et esthétiques. J'allie créativité et expertise technique
          pour des solutions sur mesure et des expériences utilisateur de
          qualité.
        </p>
        <div className="container__button-home">
          {filteredItems.map((item) => (
            <div key={item.id}>
              <Link to={item.to} onClick={(e) => e.preventDefault()}>
                <div
                  className={`custom__button-home ${
                    item.id === 'contact' ? 'custom__button-home-contact' : ''
                  } slide-bottom`}
                  onClick={() => handleNavigation(item)}
                >
                  {item.label}
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
