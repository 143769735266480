// images.js
import godev from '../assets/logo.svg';
import heroDesktop from '../assets/hero-desktop.gif';
import heroDesktop2 from '../assets/hero-desktop2.webp';
import heroMobile from '../assets/hero-mobile.gif';
import heroMobile2 from '../assets/hero-mobile2.webp';
import guichGourmet from '../assets/Guich&Gourmet.webp';
import guichGourmetMobile from '../assets/Guich&GourmetMobile.webp';
import GourmetGrill from '../assets/Gourmet&grill.webp';
import GourmetGrillMobile from '../assets/Gourmet&grill-mobile.webp';

export default {
  godev,
  heroDesktop,
  heroDesktop2,
  heroMobile,
  heroMobile2,
  guichGourmet,
  guichGourmetMobile,
  GourmetGrill,
  GourmetGrillMobile,
};
