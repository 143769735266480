// projects.js
import images from './images';
const projects = [
  {
    id: 'project1',
    title: 'Guich & Gourmet',
    description:
      'Guich & Gourmet est une application web pour un restaurant fictif qui met en avant la gastronomie authentique et créative. Réalisée avec React.js, elle propose un design moderne et épuré avec, une navigation fluide et un style visuel soigné.',
    image: images.guichGourmet,
    imageMobile: images.guichGourmetMobile,
    link: 'https://guichgourmet.godeveloper.fr',
    color: 'color-proj-1',
  },
  {
    id: 'project2',
    title: 'Gourmet & Grill',
    description:
      'Gourmet & Grill est une application web pour un restaurant fictif, spécialisé dans une cuisine audacieuse et généreuse. Le design sombre et élégant et réalisé avec React.js',
    image: images.GourmetGrill,
    imageMobile: images.GourmetGrillMobile,
    link: 'https://gourmetgrill.godeveloper.fr/',
    color: 'color-proj-2',
  },
];

export default projects;
